require('../css/landing.scss');
require('../css/venobox.css');
require('../js/libs/venobox.js');
import Vimeo from '@vimeo/player';

const work__container = document.querySelector('#js-work');
const work__video__container = document.querySelector('#js-work #js-work__video__container');
const work__brief = document.querySelector('#js-work #js-work__brief');
const work__snapshot = document.querySelector('#js-work #js-work__snapshot');
const work__snapshot_container = document.querySelector('#js-work #js-work__snapshot__container');
const work__play_btn = document.querySelector('#js-work #js-work__play_btn');
const work__spinner = document.querySelector('#js-work #js-work__spinner');
const work__video = document.querySelector('#js-work #js-work__video');
const works = document.querySelectorAll('.js-works__selector');
let largeVideoDisplay = false;
let vimeo;

$(document).ready(function(){

  var $ctaClose = $(".cta-close");
  var $ctaBox = $(".cta-box");

  function cta_in(){
    if( $(window).scrollTop() > 60 ){
      $ctaBox.addClass('cta-in', 2000);
    }else{
      $ctaBox.removeClass('cta-in', 2000);
    }
  }

  $(window).bind('scroll',cta_in);

  $ctaClose.on('click', function(e){

    $(this).fadeOut();
    $ctaBox.delay(350).addClass('closed');
  });

  viewMoreLess();

  if (works.length > 0) {
    initWorkDescription();
  }
});

const $descriptionContainer = $("#left-content-description > article > .description--container");

/**
 * Return true if the window width is less than 600px.
 * @return {boolean}
 */
const isMobile = () => {
  return window.innerWidth <= 600;
};

/**
 * Set work description on click
 */
const workDescriptionListener = () => {
  works.forEach((work) => {
    work.addEventListener('click', () => {
      setNormalPlayer();
      setElementActive(work);
      setDescription(work);
    });
  });
};

/**
 * Set a description active
 * @param work
 */
const setElementActive = (work) => {
  works.forEach((element) => {
    element.classList.remove('active');
  });
  work.classList.add('active');
};

/**
 * Set a description
 */
const setDescription = (work) => {
  work__brief.innerHTML = work.dataset.brief;
  work__snapshot.src = work.dataset.snapshot;
  if (isMobile()) {
    $(".venobox").venobox({
      cb_post_open: function(obj, gallIndex, thenext, theprev){
        vimeo = updateVideo(vimeo, work.dataset.video);
        if (!vimeo) { return; }
        playVideo(vimeo);
      }
    }).trigger('click');
  } else {
    vimeo = updateVideo(vimeo, work.dataset.video);
    if (!vimeo) { return; }
    playVideo(vimeo);
  }
};

/**
 * Set a Vimeo video player
 * @param video_url
 * @return {boolean|Player}
 */
const setVideo = video_url => {
  showLoader();
  const options = {
    url: video_url,
    loop: false,
    autoplay: false
  };
  const el = isMobile() ? document.querySelector('.vbox-content #js-work__video') : document.querySelector('#js-work__video');
  if (!el) { return false; }
  return new Vimeo(el, options);
};

/**
 * Set a Vimeo video player
 * @param player
 * @param video_url
 * @return {Player}
 */
const updateVideo = (player, video_url) => {
  hidePlayer();
  if (player) {
    player.destroy();
  }
  return setVideo(video_url)
};

/**
 * Show the Vimeo player
 */
const showPlayer = () => {
  work__video__container.classList.remove('hide');
  work__video__container.classList.add('show');
};

/**
 * Hide the Vimeo player
 */
const hidePlayer = () => {
  work__video__container.classList.remove('show');
  work__video__container.classList.add('hide');
  showPlayButton();
};

/**
 * Play the Vimeo video
 * @param player
 */
const playVideo = player => {
  player.on('loaded', () => {
    player.play().catch(error => {
      console.log(error);
    });
    showPlayer();
  });

  player.on('ended', () => {
    work__snapshot_container.addEventListener('click', () => {
      showPlayer();
      player.play();
    });
    hidePlayer();
  });
};


/**
 * Play the video when the page is loaded
 */
const initWorkDescription = () => {

  workDescriptionListener();

  if (isMobile()) { return; }

  if (work__video.dataset.category_video) {
    vimeo = setVideo(work__video.dataset.category_video);
    setLargerPlayer();
    vimeo.on('loaded', () => {
      vimeo.play().catch(error => {
        console.log(error);
      });
      showPlayer();
    });
    vimeo.on('ended', () => {
      if (work__video.dataset.general_video) {
        vimeo = updateVideo(vimeo, work__video.dataset.general_video);
        if (!vimeo) { return; }
        setLargerPlayer();
        playVideo(vimeo);
      } else {
        work__snapshot_container.addEventListener('click', () => {
          showPlayer();
          vimeo.play();
        });
        hidePlayer();
      }
    });
  } else if (work__video.dataset.general_video) {
    vimeo = setVideo(work__video.dataset.general_video);
    setLargerPlayer();
    playVideo(vimeo);
  } else {
    vimeo = setVideo(works[0].dataset.video);
    setNormalPlayer();
    playVideo(vimeo);
  }
};

const showLoader = () => {
  work__play_btn.style.display = 'none';
  work__spinner.style.display = 'block';
};

const showPlayButton = () => {
  work__play_btn.style.display = 'block';
  work__spinner.style.display = 'none';
};

const setLargerPlayer = () => {
  work__container.classList.add('large');
  largeVideoDisplay = true;
};

const setNormalPlayer = () => {
  work__container.classList.remove('large');
  if (window.matchMedia("(min-width: 1281px)").matches && largeVideoDisplay === true) {
    largeVideoDisplay = false;
    const initialHeightVideo = $('#js-work__video').outerHeight();
    const calcNewHeightVideo = initialHeightVideo - (initialHeightVideo * 0.25);

    $descriptionContainer.css("height", calcNewHeightVideo);
  }
};

/**
 * READ LESS/MORE
 * ==============
 *
 * Creating a “read more” link that extends the content on the page.
 * Set the content inside a div with the id="view-more-less--container".
 *
 * Example:
 * <div id="view-more-less--container">
 *     </p>
 *     </p>
 *     </a>
 *     </p>
 * </div>
 *
 * The first element is displayed, the rest of the elements
 * are hidden and displayed when clicking on the button.
 */
const viewMoreLess = () => {
  const view_more_less__container = document.getElementById('view-more-less--container');
  const content = view_more_less__container.children;

  // If there is more than one element.
  if (content.length > 1) {
    const content_array = Array.from(content);
    view_more_less__container.classList.add('display-less');

    // Create the container
    const view_more_less__content = document.createElement("div");
    view_more_less__content.className = 'view-more-less--content';

    // Create the button
    const view_more_less__button = document.createElement("button");
    view_more_less__button.className = 'view-more-less--button';
    view_more_less__button.innerText = view_more_less__container.dataset.readMoreText ? view_more_less__container.dataset.readMoreText : 'Read more';

    // Append in the container
    view_more_less__container.appendChild(view_more_less__content);
    view_more_less__container.appendChild(view_more_less__button);

    // Set the elements except the first one in the container
    content_array.splice(0, 1);
    content_array.forEach((element) => {
      view_more_less__content.appendChild(element);
    });

    // Toggle the container when clicking on the button
    view_more_less__button.addEventListener('click', () => {
      if (view_more_less__container.classList.contains('display-more')) {
        view_more_less__container.classList.add('display-less');
        view_more_less__container.classList.remove('display-more');
        view_more_less__content.style.maxHeight = 0 +'px';
        view_more_less__button.innerText = view_more_less__container.dataset.readMoreText ? view_more_less__container.dataset.readMoreText : 'Read more';
      } else if (view_more_less__container.classList.contains('display-less')) {
        view_more_less__container.classList.add('display-more');
        view_more_less__container.classList.remove('display-less');
        let view_more_less__content_height = 0;
        view_more_less__content.childNodes.forEach( element => {
          view_more_less__content_height += element.clientHeight;
        });
        view_more_less__content.style.maxHeight = view_more_less__content_height + 18 +'px';
        view_more_less__button.innerText = view_more_less__container.dataset.readLessText ? view_more_less__container.dataset.readLessText : 'Read less';
      }
    });

    window.onresize = function() {
      if (view_more_less__container.classList.contains('display-more')) {
        let view_more_less__content_height = 0;
        view_more_less__content.childNodes.forEach( element => {
          view_more_less__content_height += element.clientHeight;
        });
        view_more_less__content.style.maxHeight = view_more_less__content_height + 15 + 'px';
      }
    };

  }
};

const videoHeader = () =>{
  const header__video = document.querySelector('#js-header__video');
  const header__image = document.querySelector('.work-details header .parallax-background');
  const header = document.querySelector('.landing-header');

  if (!isMobile() || !header__video) { return; }

  let player = null;

  if (header__video.dataset.category_video){
    header.classList.add('header-video');
    const options = {
      url: header__video.dataset.category_video,
      loop: false,
      autoplay: true,
      muted: true,
      controls: false
    };

    player = new Vimeo(header__video, options);

    player.on('loaded', () => {
      player.play().catch(error => {
        console.log(error);
      });
    });

    player.on('play', () => {
      if (header__image) {
        header__image.classList.add('hide');
      }
    });

    player.on('ended', () => {
      if (header__video.dataset.general_video) {
        player.destroy();

        const options = {
          url: header__video.dataset.general_video,
          loop: false,
          autoplay: true,
          muted: true,
          controls: false
        };

        player = new Vimeo(header__video, options);

        player.on('ended', () => {
          if (header__image) {
            header__image.classList.remove('hide');
          }
        });
      } else {
        if (header__image) {
          header__image.classList.remove('hide');
        }
      }
    });
  } else if (header__video.dataset.general_video){
    header.classList.add('header-video');
    const options = {
      url: header__video.dataset.general_video,
      loop: false,
      autoplay: true,
      muted: true,
      controls: false
    };

    player = new Vimeo(header__video, options);

    player.on('loaded', () => {
      player.play().catch(error => {
        console.log(error);
      });
    });

    player.on('play', () => {
      if (header__image) {
        header__image.classList.add('hide');
      }
    });

    player.on('ended', () => {
      if (header__image) {
        header__image.classList.remove('hide');
      }
    });
  }
  return false;
};

videoHeader();
